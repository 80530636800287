import Icon from './icon'
import PropTypes from 'prop-types'
import React from 'react'

import './share-icons.scss'

function ShareIcons(props) {
    return (
        <div className="share-icons">
            <a href={`https://www.facebook.com/sharer.php?u=${process.env.GATSBY_SPA_URL}${props.path}`} target="_blank" rel="noopener noreferrer">
                <Icon name="facebook-solid"/>
            </a>
            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${process.env.GATSBY_SPA_URL}${props.path}`} target="_blank" rel="noopener noreferrer">
                <Icon name="linkedin-solid"/>
            </a>
            <a href={`https://twitter.com/intent/tweet?url=${process.env.GATSBY_SPA_URL}${props.path}`} target="_blank" rel="noopener noreferrer">
                <Icon name="twitter-solid"/>
            </a>
        </div>
    )
}

export default ShareIcons

ShareIcons.propTypes = {
    path: PropTypes.string,
}
