import BackgroundImage from 'gatsby-background-image'
import CategoryTag from './category-tag'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { formatDate } from '../helpers/dates'
import { getSlug } from '../helpers/get-slug'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import ShareIcons from './share-icons'

const HeaderHero = ({ title, tags, category, updatedAt, readingTime, path, image, leadingParagraph, parentPage, preview, readMoreLink }) => {
    return (
        <header className="resource-header full-width">
            <div className="resource-header-wrapper constrain-width">
                <div className="resource-header-section">
                    <div className="resource-header-section-inner">
                        <div className="resource-header-top-wrapper">
                            { tags && <p className="tags">Tags:<strong>{tags.map((tag, key) => { return <span className="tag" key={key}>{tag}</span> })}</strong></p>}
                            {
                                parentPage &&
                                    <div className="breadcrumbs">
                                        <Link className="breadcrumbs-link" to={getSlug(parentPage.internal.type, parentPage.slug)}>{parentPage.title} &gt;</Link> {title}
                                    </div>
                            }
                        </div>
                        <div className="resource-header-bottom-wrapper">
                            {
                                category && updatedAt && readingTime &&
                                    <div className="resource-header-meta">
                                        <CategoryTag title={category}/>
                                        <div className="date">{formatDate(updatedAt)}</div>
                                        <div className="reading-time">{readingTime} min read</div>
                                    </div>
                            }
                            <h1 className="title heading-2">{title}</h1>
                            {
                                (leadingParagraph || preview) &&
                                    <div className="leading-paragraph">
                                        {leadingParagraph && documentToReactComponents(leadingParagraph.json)}
                                        {preview && <p className="preview">{preview}</p>}
                                    </div>
                            }
                            {
                                path && !readMoreLink &&
                                    <div className="share">
                                        Share&nbsp;
                                        <ShareIcons path={path}/>
                                    </div>
                            }
                            {
                                readMoreLink &&
                                    <Link className="read-more" to={readMoreLink}>Read more</Link>
                            }
                        </div>
                    </div>
                </div>
                { image &&
                    <div className="resource-header-section right-image">
                        <BackgroundImage
                            Tag="section"
                            className="slide"
                            fluid={image.image.fluid}
                            backgroundColor="#040e18">
                            <div className="wedge">
                                <svg width="100%" height="100%" viewBox="0 0 250 500">
                                    <polygon points="0,0 0,500 250,500" style={{ fill: 'rgb(0, 58, 93)' }}/>
                                </svg>
                            </div>
                        </BackgroundImage>
                    </div>
                }
            </div>
        </header>
    )
}

HeaderHero.propTypes = {
    title: PropTypes.string.isRequired,
    leadingParagraph: PropTypes.object,
    updatedAt: PropTypes.string,
    category: PropTypes.string,
    readingTime: PropTypes.number,
    tags: PropTypes.array,
    path: PropTypes.string,
    preview: PropTypes.array,
    readMoreLink: PropTypes.string,
    image: PropTypes.shape({
        image: PropTypes.shape({
            fluid: PropTypes.shape(({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
            })),
        }),
    }),
    parentPage: PropTypes.shape(({
        internal: PropTypes.shape(({
            type: PropTypes.string,
        })),
        title: PropTypes.string,
        slug: PropTypes.string,
    })),
}

export default HeaderHero
