export function getSlug(type, slug) {
    let prefix

    switch (type) {
        case ('ContentfulPage') :
            prefix = ''
            break

        case ('ContentfulResource') :
            prefix = '/news'
            break

        default:
            prefix = ''
    }

    return `${prefix}/${slug === 'home' ? '' : slug}`
}
