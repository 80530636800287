import PropTypes from 'prop-types'
import React from 'react'
import './category-tag.scss'

const CategoryTag = (props) => {
    const { title } = props
    const categoryLink = encodeURI(`/news/?menu[category]=${title}`)

    return (
        <div onClick={(event) => {
            event.preventDefault()
            window.location.href = categoryLink
        }} className="category-tag">{title}</div>
    )
}

CategoryTag.propTypes = {
    title: PropTypes.string,
}

export default CategoryTag
