import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

const RenderContentBlocks = (contentBlocks) => {
    return contentBlocks.map((block) => {
        if (block && block.internal && block.internal.type === 'ContentfulRichTextContentBlock') {
            return documentToPlainTextString(block.body.json)
        }
    })
}

export default RenderContentBlocks
